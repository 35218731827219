<script setup lang="ts">
import { MessageCircleQuestion } from 'lucide-vue-next';
import { useJivoStore } from '~/stores/JivoStore';

const jivoStore = useJivoStore();

function open() {
  if (window.jivo_api) {
    window.jivo_api.open();
  } else {
    alert(
      'Похоже, что у вас включен блокировщик рекламы, сайт может работать некорректно. Добавьте сайт begemot.ai в исключения или выключите блокировщик рекламы и обновите страницу.'
    );
  }
}

const style = computed(() => {
  return {
    bottom: `${jivoStore.bottomOffset}px`,
    right: `${jivoStore.rightOffset}px`,
    display: jivoStore.isHidden ? `none` : `flex`,
  };
});
</script>

<template>
  <client-only>
    <button
      class="jivo-button"
      :style="style"
      @click="open()"
    >
      <MessageCircleQuestion
        :size="26"
        style="padding: 0 0 1px 1px"
      />
    </button>
  </client-only>
</template>

<style scoped lang="scss">
.jivo-button {
  bottom: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  position: fixed;
  transition: opacity 0.2s;
  visibility: visible;
  z-index: 2147483647;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  border: 1px solid $line-stroke-gray;
  background: #fff;
  box-shadow: 0 0 16px 0 $background-theme-fade;
  cursor: pointer;
  @include media-breakpoint-up(md) {
    display: none !important;
  }

  &.hidden {
    opacity: 0;
  }

  &:focus {
    outline: none;
  }

  svg {
    color: $foreground-theme;
  }
}
</style>
