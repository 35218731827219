<script setup lang="ts">
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet';
import '@webzlodimir/vue-bottom-sheet/dist/style.css';
import { useAnalyticsStore } from '~/stores/AnalyticsStore';
import { useJivoStore } from '~/stores/JivoStore';
import { useUserStore } from '~/stores/UserStore';
import { useTelegramPopupStore } from '~/stores/TelegramPopupStore';
import VButton from '~/components/VButton.vue';
import VModal from '~/components/common/VModal.vue';

const BLOCKED_EMAILS = ['test@fake.ru'];
const oneDayInMs = 24 * 60 * 60 * 1000;

const analyticsStore = useAnalyticsStore();
const userStore = useUserStore();
const bottomSheet = ref<InstanceType<typeof VueBottomSheet>>();

const store = useTelegramPopupStore();
const isModalClosed = ref(false);
const isTimeoutExpired = ref(false);
const isUserTyping = ref(false);
const isSubscribedToTelegram = useCookie('isSubscribedToTelegram', { maxAge: 60 * 60 * 24 * 90, default: () => false });
const telegramModalShownDate = useCookie<string | null>('telegramModalShownDate', {
  maxAge: 60 * 60 * 24 * 2, // 2 days
  default: () => null,
});
const isDayPassed = computed(() => {
  if (!telegramModalShownDate.value) {
    return true; // If no subscription date exists, we can show the modal
  }

  const lastShownDate = new Date(telegramModalShownDate.value);
  const now = new Date();

  const timeDifferenceInMs = now.getTime() - lastShownDate.getTime();
  return timeDifferenceInMs >= oneDayInMs;
});
const isAuthorized = ref(!!userStore.user);
const jivoStore = useJivoStore();

const isModalShown = computed(
  () =>
    !isSubscribedToTelegram.value &&
    isAuthorized.value &&
    !isUserTyping.value &&
    isTimeoutExpired.value &&
    !BLOCKED_EMAILS.includes(userStore.user?.email || '') &&
    !store.isHidden &&
    isDayPassed.value &&
    !isModalClosed.value
);

watch(isModalShown, (value: boolean) => {
  if (value) {
    jivoStore.isHidden = true;
    analyticsStore.telegramPopupShow();
    bottomSheet.value.open();
  } else {
    jivoStore.isHidden = false;
    telegramModalShownDate.value = new Date().toISOString();
    analyticsStore.telegramPopupClose();
  }
});

let timer: ReturnType<typeof setTimeout> | undefined;

const closeModal = () => {
  isModalClosed.value = true;
  bottomSheet.value.close();
};

const startTimer = () => {
  if (!isUserTyping.value) {
    timer = setTimeout(() => {
      isTimeoutExpired.value = true;
    }, 10 * 1000); // 10 секунд
  }
};

const stopTimer = () => {
  if (timer) {
    clearTimeout(timer);
  }
};

const handleFocus = () => {
  isUserTyping.value = true;
  stopTimer(); // Останавливаем таймер, если пользователь что-то вводит
};

const handleBlur = () => {
  isUserTyping.value = false;
  startTimer(); // Перезапускаем таймер, если пользователь перестал вводить текст
};

const onSubscribeClick = () => {
  analyticsStore.telegramPopupSubscribe();
  isSubscribedToTelegram.value = true;
  closeModal();
  navigateTo('https://t.me/projects_ai', {
    external: true,
    open: {
      target: '_blank',
    },
  });
};

const onClose = () => {
  closeModal();
};

onMounted(() => {
  document.addEventListener('focusin', (event: Event) => {
    const target = event.target as HTMLElement;
    if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA') {
      handleFocus();
    }
  });

  document.addEventListener('focusout', (event: Event) => {
    const target = event.target as HTMLElement;
    if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA') {
      handleBlur();
    }
  });

  startTimer();
});

onBeforeUnmount(() => {
  stopTimer();
  document.removeEventListener('focusin', handleFocus);
  document.removeEventListener('focusout', handleBlur);
});
</script>

<template>
  <client-only>
    <v-modal
      v-if="isModalShown"
      class="telegram-popup"
      :title="`Подпишись на наш канал в Telegram`"
      @close="onClose"
    >
      <div class="telegram-popup__container">
        <p class="telegram-popup__text">
          Там можно узнать обо всех актуальных новостях и скидках, а также поучаствовать в развитии продукта
        </p>
        <v-button
          style="width: 100%"
          @click="onSubscribeClick"
          >Подписаться</v-button
        >
      </div>
    </v-modal>
    <vue-bottom-sheet
      ref="bottomSheet"
      @closed.once="onClose"
    >
      <div class="telegram-bottom-sheet__container telegram-popup__container">
        <h3 class="telegram-bottom-sheet__heading">Подпишись на наш канал в Telegram</h3>
        <p class="telegram-bottom-sheet__text">
          Там можно узнать обо всех актуальных новостях и скидках, а также поучаствовать в развитии продукта
        </p>
        <v-button
          class="telegram-bottom-sheet__support-button"
          style="width: 100%"
          @click.once="onSubscribeClick"
          >Подписаться</v-button
        >
      </div>
    </vue-bottom-sheet>
  </client-only>
</template>

<style lang="scss">
.telegram-bottom-sheet__container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;

  .telegram-bottom-sheet__heading {
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    margin: 0;
  }

  .telegram-bottom-sheet__text {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: $foreground-gray;
  }
}

.telegram-popup {
  .modal__container {
    padding: 24px;
  }

  .telegram-popup__container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .telegram-popup__text {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: $foreground-gray;
    }

    .telegram-popup__error {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: $text-status-destructive;
      hyphens: auto;
    }
  }
}
</style>
